<template>
    <v-card>
        <v-card-title>
            <span class="headline">Edit Province</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <v-text-field v-model="editedData.provinceName" :error-messages="provinceNameErrors"
                                  label="Province Name" required @input="$v.editedData.provinceName.$touch()"
                                  @blur="$v.editedData.provinceName.$touch()"></v-text-field>
                    <v-text-field v-model="editedData.provinceCode" label="Province Code" required></v-text-field>
                    <v-btn class="mr-4" @click="submit">submit</v-btn>
                </form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: 'createAdminForm',
        props: ['success', 'provinceId'],
        computed: {
            provinceNameErrors() {
                const errors = []
                if (!this.$v.editedData.provinceName.$dirty) return errors
                !this.$v.editedData.provinceName.required && errors.push('Last Name is required.')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
            created: {
                handler: function (val) {
                    console.log(val)
                    this.$emit("formResponse", val)
                },
                deep: true
            },
        },
        data() {
            return {
                created: false,
                editedData: {
                    ministryID: 1,
                    provinceCode: '',
                    provinceName: '',
                },
            }
        },
        destroyed() {
        },
        validations: {
            editedData: {
                provinceName: {required}
            },
        },
        mounted() {
            this.$forceUpdate()
            this.formData()
        },
        methods: {
            async formData() {
                console.log(this.provinceId)
                let data = {"ProvinceID": this.provinceId}
                await axios.get('Province/GetProvinceByID', {params: data}).then((response) => {
                    this.editedData.provinceId = response.data.provinceID
                    this.editedData.provinceCode = response.data.provinceCode
                    this.editedData.provinceName = response.data.provinceName
                })
            },
            submit() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {
                    let vm = this
                    let param = {
                        "ministryID": this.editedData.ministryID,
                        "provinceID": this.editedData.provinceId,
                        "provinceName": this.editedData.provinceName,
                        "provinceCode": this.editedData.provinceCode
                    }
                    axios.post('Province/UpdateProvince', param).then((response) => {
                        if (response.status == 200) {
                            vm.created = true
                        }
                    })
                    this.submitStatus = 'PENDING'
                    setTimeout(() => {
                        this.submitStatus = 'OK'
                    }, 500)
                }
            }
        }
    }
</script>

<style scoped>

</style>