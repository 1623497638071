<template>
    <v-content>
        <!--        <v-dialog v-model="dialogCreate" max-width="500px">-->
        <!--            <template v-slot:activator="{ on }">-->
        <!--                <v-btn color="primary" dark class="mb-2 float-right" v-on="on" :success="success" >Add Province</v-btn>-->
        <!--            </template>-->
        <!--            <createProvinceForm :success="success" @formResponse="onResponse" />-->
        <!--        </v-dialog>-->
        <v-dialog max-width="500px" v-if="permissionData.Province_W" v-model="dialogEdit">
            <editProvinceForm
                    :provinceId="editedData.provinceId"
                    :success="success"
                    @formResponse="onResponse"
                    v-if="dialogEdit"
            />
        </v-dialog>

        <!--  <v-expansion-panels hover>
            <v-expansion-panel>
              <v-expansion-panel-header>Filter</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row align="start">
                    <v-col class="float-right" cols="12" sm="12">
                      <v-text-field label="Seartch input" v-model="serverParams.search"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-btn color="primary" dark @click="searchInput">
                      <i class="material-icons">search</i>Search
                    </v-btn>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>-->

        <vue-good-table
                :columns="tableColumns"
                :isLoading.sync="isLoading"
                :pagination-options="{ enabled: true,perPageDropdown: [50, 100,200] }"
                :rows="rows"
                :totalRows="totalRecords"
                @on-column-filter="onColumnFilter"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange"
                mode="remote"
        >
            <template slot="table-row" slot-scope="props" v-if="permissionData.Province_W">
        <span v-if="props.column.field == 'actions'">
          <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
        </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
            </template>
        </vue-good-table>
    </v-content>
</template>

<script>
    import axios from "axios";
    // import createProvinceForm from "./create"
    import editProvinceForm from "./edit";

    export default {
        name: "List",
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New User" : "Edit User";
            },
            ministryIDErrors() {
                const errors = [];
                if (!this.$v.editedData.ministryID.$dirty) return errors;
                !this.$v.editedData.ministryID.required &&
                errors.push("First Name is required.");
                return errors;
            },
            provinceNameErrors() {
                const errors = [];
                if (!this.$v.editedData.provinceName.$dirty) return errors;
                !this.$v.editedData.provinceName.required &&
                errors.push("Last Name is required.");
                return errors;
            },
            tableColumns(){
                return [
                    {
                        label: this.$t('table.id'),
                        field: "provinceID"
                    },
                    {
                        label: this.$t('table.province_name'),
                        field: "provinceName"
                    }
                ]
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            }
        },
        components: {
            // createProvinceForm,
            editProvinceForm
        },
        data() {
            return {
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                popout: true,
                isLoading: false,
                permissionData: {},
                columns: [
                    {
                        label: this.$t('table.id'),
                        field: "provinceID"
                    },
                    {
                        label: this.$t('table.province_name'),
                        field: "provinceName"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "provinceID",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                editedIndex: -1,
                editedData: {
                    ministryID: "",
                    provinceCode: "",
                    provinceName: ""
                }
            };
        },
        mounted() {
            this.loadItems();
            this.addColumn();
        },
        methods: {
            onResponse(data) {
                console.log("yets" + data);
                if (data == true) {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.loadItems();
                }
            },
            editItem(props) {
                this.editedData.provinceId = props.provinceID;
                this.dialogEdit = true;
            },

            deleteItem(item) {
                const index = this.user.indexOf(item);
                confirm("Are you sure you want to delete this item?") &&
                this.user.splice(index, 1);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            searchInput() {
                this.loadItems();
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },


            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            // load items is what brings back the rows from server
            addColumn() {
                this.permissionData = JSON.parse(localStorage.getItem("tokenData"));
                if (this.permissionData.Province_W) {
                    this.columns.push({
                        label: "Actions",
                        field: "actions",
                        sortable: false
                    });
                }
            },
            async loadItems() {
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    ProvinceName: this.serverParams.search
                };

                axios.post("Province/ProvinceList", param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }
</style>
